import { For, Show, createEffect, onCleanup, onMount, splitProps } from "solid-js";
import { ShowMaterialLessonViewProps } from "../../../models";
import { Block } from "./block";
import { elementStyleToHTMLStyle } from "../../../methods";

export function ShowMaterialLesson(props: ShowMaterialLessonViewProps) {
  const [local, others] = splitProps(props, ["$", "content", "edit", "style"]);
  let ref: HTMLDivElement = undefined;

  // keeping this in setup incase it's used by edit page builder
  if (!local.edit) {
    // local.$.actions.setLayout({
    //   title: `PageBuilder for ${local.content.name}`,
    // });
  }

  createEffect(() => {
    if (ref) {
      ref.setAttribute("style", elementStyleToHTMLStyle(local.content.style));
    }
    // console.log("updated show page builder :: ", local.content);
  });
  function contextMenuEvent(e: MouseEvent) {
    e.preventDefault();
  }
  onMount(() => {
    if (ref) {
      ref.addEventListener("contextmenu", contextMenuEvent);
    }
  });
  onCleanup(() => {
    ref.removeEventListener("contextmenu", contextMenuEvent);
  });
  return (
    <div {...others} ref={ref}>
      <Show
        when={local.content.blocks && local.content.blocks.length > 0}
        fallback={local.content.name ?? "Unedited Group"}
      >
        <For each={local.content.blocks}>
          {(block, index) => {
            return <Block block={block} />;
          }}
        </For>
      </Show>
    </div>
  );
}
